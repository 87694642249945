
import { computed, createVNode, defineComponent, reactive, ref } from 'vue';
import ForEditor from '@/components/for-editor';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import {
  create_address,
  create_bank_account_by_wechat,
  create_beneficiary_by_wechat,
  create_next_of_kin_by_wechat,
  create_person_reference_number_by_wechat,
  create_resume,
  create_social_insurance,
  delete_bank_account,
  delete_beneficiary,
  export_resume,
  get_wechat_update_record_status,
  query_adviser_list,
  query_resume_list,
  update_adviser_by_wechat,
  update_bank_account_by_wechat,
  update_beneficiary_by_wechat,
  update_next_of_kin_by_wechat,
  update_person_reference_number_by_wechat,
  update_resume,
} from '@/api/cerp-model';
import modalBox from '@/components/form-modal/modal-tools';
import { query_bank_manage_list } from '@/api/baseinfo-model';
import PageModel4 from '@/components/page-model4/index.vue';
import bus from '@/utils/bus';
import { useForm } from 'ant-design-vue/lib/form';
import { useI18n } from 'vue-i18n';
import { query_all_nsfile_list } from '@/api/nsbcs-model';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import locales from '@/locales';

export default defineComponent({
  components: {
    ForEditor,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PageModel4,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const {t} = useI18n();

    const current_org = store.getters['user/current_org'];
    const adviser = computed(() => store.getters[`user/adviser`]);
    const is_induction_process = computed(() => store.getters['user/is_induction_process']);
    const disabled: any = ref(false);
    const disabled_adviser: any = ref(false);
    const disabled_bank: any = ref(false);
    const disabled_insurance: any = ref(false);
    const disabled_lian_xi: any = ref(false);
    const disabled_can_kao_hao: any = ref(false);
    const loading_Nextofkin: any = ref(false);
    const loading_Beneficiary: any = ref(false);
    const loading_SocialInsurance: any = ref(false);
    const is_bank_create: any = ref(true);
    const is_can_kao_hao: any = ref(true);
    const is_lian_xi: any = ref(true);
    const is_beneficiary: any = ref(true);
    const activeKey: any = ref('基本信息');
    const checked: any = ref(false);
    const file_list: any = ref([]);
    const data_list: any = reactive({});
    const file_list1: any = ref([]);
    const percent: any = ref(0);
    const state: any = reactive({
      introduction: '',
      skill: [],
      language: [],
      datas: [],
      training: [],
      educations: [],
      experiences: [],
      appendix_ids: [],
      resume_id: null,
    });
    let is_create1: any = true;
    let num: any = 0;
    const tags_list = ref([
      {
        key: '基本信息',
        tab1: t('page.Induction.personal_information'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '银行账户',
        tab1: t('page.Induction.bank_account'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '个人材料',
        tab1: t('router.title.cerp-personreferencenumber'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '紧急联系人',
        tab1: t('router.title.cerp-nextofkin'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '保险受益人',
        tab1: t('page.Induction.Insurance_beneficiary'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '社保',
        tab1: t('page.Induction.social_security'),
        slots: {tab: 'tagsList'},
      },
      {
        key: '个人简历',
        tab1: t('page.Induction.resume'),
        slots: {tab: 'tagsList'},
      },
    ])
    const form_info = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [12, 12],
      },
      fields: [
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'input',
          name: 'full_name',
          label_i18n: 'page.Induction.form.label.full_name',
          label: '姓名',
          disabled: true,
          placeholder: '请填写姓名',
          placeholder_i18n: 'page.Induction.form.placeholder.full_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'other_name',
          label_i18n: 'page.Induction.form.label.other_name',
          label: '中文姓名',
          disabled: disabled_adviser,
          placeholder: '请填写中文姓名',
          placeholder_i18n: 'page.Induction.form.placeholder.other_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'remoteselect',
          name: 'nationality',
          label: '国籍',
          label_i18n: 'page.Induction.form.label.nationality',
          disabled: disabled_adviser,
          placeholder: '请选择国籍',
          placeholder_i18n: 'page.Induction.form.placeholder.nationality',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-country',
        },
        {
          type: 'input',
          name: 'mobile',
          label: '手机',
          label_i18n: 'page.Induction.form.label.mobile',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写手机',
          placeholder_i18n: 'page.Induction.form.placeholder.mobile',
        },
        {
          type: 'remoteselect',
          name: 'person_type_id',
          label: '个人类别',
          label_i18n: 'page.Induction.form.label.person_type_id',
          disabled: true,
          placeholder: '请选择个人类别',
          placeholder_i18n: 'page.Induction.form.placeholder.person_type_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-persontype',
        },
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'input',
          name: 'wechat',
          label: '微信账号',
          label_i18n: 'page.Induction.form.label.wechat',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写微信账号',
          placeholder_i18n: 'page.Induction.form.placeholder.wechat',
        },
        {
          type: 'input',
          name: 'qq',
          label: 'QQ账号',
          label_i18n: 'page.Induction.form.label.qq',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写QQ账号',
          placeholder_i18n: 'page.Induction.form.placeholder.qq',
        },
        {
          type: 'input',
          name: 'personal_email',
          label: '个人邮箱',
          label_i18n: 'page.Induction.form.label.personal_email',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写个人邮箱',
          placeholder_i18n: 'page.Induction.form.placeholder.personal_email',
        },
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'remoteselect',
          name: 'work_address_id',
          label: '工作地址',
          label_i18n: 'page.Induction.form.label.work_address_id',
          disabled: disabled_adviser,
          placeholder: '请选择工作地址',
          placeholder_i18n: 'page.Induction.form.placeholder.work_address_id',
          datasourceType: 'remote',
          mode: 'default',
          init_model: {adviser_id: adviser.value.adviser_id, address_type: 1},
          allowClear: true,
          labelKey: 'country__name',
          valueKey: 'id',
          modalType: 'cerp-address',
          cb: () => {
            return new Promise(function (resolve) {
              const editModal1 = reactive({
                visible: true,
                title: t('page.Induction.form.title.New_work'),
                loading: false,
                form: {
                  settings: {
                    labelAlign: 'right',
                    layout: 'vertical',
                    col: [],
                  },
                  fields: [
                    {
                      type: 'remoteselect',
                      name: 'country_id',
                      label: '国家',
                      label_i18n: 'page.Induction.form.label.work_address_id.country_id',
                      disabled: false,
                      placeholder: '请选择国家',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.country_id',
                      datasourceType: 'remote',
                      mode: 'default',
                      allowClear: true,
                      modalType: 'baseinfo-country',
                    },
                    {
                      type: 'input',
                      name: 'state',
                      label: '州/省',
                      label_i18n: 'page.Induction.form.label.work_address_id.state',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写州/省',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.state',
                    },
                    {
                      type: 'input',
                      name: 'city',
                      label: '城市',
                      label_i18n: 'page.Induction.form.label.work_address_id.city',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写城市',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.city',
                    },
                    {
                      type: 'input',
                      name: 'street',
                      label: '街道',
                      label_i18n: 'page.Induction.form.label.work_address_id.street',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写街道',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.street',
                    },
                    {
                      type: 'input',
                      name: 'postcode',
                      label: '邮编',
                      label_i18n: 'page.Induction.form.label.work_address_id.postcode',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写邮编',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.postcode',
                    },
                  ],
                  rules: {},
                  model: reactive({
                    org_id: current_org?.id,
                    is_active: true,
                    adviser_id: adviser.value.adviser_id,
                    adviser_name: null,
                    street: null,
                    city: null,
                    state: null,
                    country_id: null,
                    postcode: null,
                    address_type: 1,
                  }),
                },
                options: {
                  address_type: [
                    {value: 0, name: t('page.Induction.form.label.home_address_id')},
                    {value: 1, name: t('page.Induction.form.label.work_address_id')},
                  ],
                },
                ok: (payload: any) => {
                  editModal1.loading = true;
                  return new Promise(resolve1 => {
                    create_address(payload)
                      .then((res: any) => {
                        message.success(t('page.Induction.form.title.Created_successfully'));
                        resolve1(null);
                        resolve(res.id as number);
                      })
                      .finally(() => {
                        editModal1.loading = false;
                      });
                  });
                },
              });
              modalBox(editModal1);
            });
          },
        },
        {
          type: 'title',
          name: '个人信息',
          name_i18n: 'page.Induction.personal_information',
        },
        {
          type: 'select',
          name: 'title',
          label: '称谓',
          label_i18n: 'page.Induction.form.label.title',
          mode: 'default',
          disabled: disabled_adviser,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择称谓',
          placeholder_i18n: 'page.Induction.form.placeholder.title',
          datasource: 'title',
        },
        {
          type: 'datepicker',
          name: 'birth_date',
          label: '出生日期',
          label_i18n: 'page.Induction.form.label.birth_date',
          disabled: disabled_adviser,
          allowClear: true,
          disabledTime: (date: any) => {
            return date >= moment();
          },
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请选择出生日期',
          placeholder_i18n: 'page.Induction.form.placeholder.birth_date',
        },
        {
          type: 'input',
          name: 'phone',
          label: '电话',
          label_i18n: 'page.Induction.form.label.phone',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写电话',
          placeholder_i18n: 'page.Induction.form.placeholder.phone',
        },
        {
          type: 'select',
          name: 'status',
          label: '个人状态',
          label_i18n: 'page.Induction.form.label.status',
          mode: 'default',
          disabled: true,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择个人状态',
          placeholder_i18n: 'page.Induction.form.placeholder.status',
          datasource: 'status',
        },
        {
          type: 'remoteselect',
          name: 'pay_currency_id',
          labelKeyList: ['code', 'currency'],
          label: '付款币种',
          label_i18n: 'page.Induction.form.label.pay_currency_id',
          disabled: true,
          placeholder: '请选择付款币种',
          placeholder_i18n: 'page.Induction.form.placeholder.pay_currency_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-country',
        },
        {
          type: 'title',
          name: '联系方式',
          name_i18n: 'page.Induction.information',
        },
        {
          type: 'input',
          name: 'whats_app',
          label: 'WhatsApp账号',
          label_i18n: 'page.Induction.form.label.whats_app',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写WhatsApp账号',
          placeholder_i18n: 'page.Induction.form.placeholder.whats_app',
        },
        {
          type: 'input',
          name: 'skypelid',
          label: 'Skype ID',
          label_i18n: 'page.Induction.form.label.skypelid',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写Skype ID',
          placeholder_i18n: 'page.Induction.form.placeholder.skypelid',
        },
        {
          type: 'input',
          name: 'work_email',
          label: '工作邮箱',
          label_i18n: 'page.Induction.form.label.work_email',
          disabled: disabled_adviser,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写工作邮箱',
          placeholder_i18n: 'page.Induction.form.placeholder.work_email',
        },
        {
          type: 'title',
          name: '个人地址',
          name_i18n: 'page.Induction.personal_address',
        },
        {
          type: 'remoteselect',
          name: 'home_address_id',
          label: '家庭地址',
          label_i18n: 'page.Induction.form.label.home_address_id',
          disabled: disabled_adviser,
          placeholder: '请选择家庭地址',
          placeholder_i18n: 'page.Induction.form.placeholder.home_address_id',
          datasourceType: 'remote',
          mode: 'default',
          init_model: {adviser_id: adviser.value.adviser_id, address_type: 0},
          allowClear: true,
          labelKey: 'display_name',
          valueKey: 'id',
          modalType: 'cerp-address',
          cb: () => {
            return new Promise(function (resolve) {
              const editModal1 = reactive({
                visible: true,
                title: t('page.Induction.form.title.New_home'),
                loading: false,
                form: {
                  settings: {
                    labelAlign: 'right',
                    layout: 'vertical',
                    col: [],
                  },
                  fields: [
                    {
                      type: 'remoteselect',
                      name: 'country_id',
                      label: '国家',
                      label_i18n: 'page.Induction.form.label.work_address_id.country_id',
                      disabled: false,
                      placeholder: '请选择国家',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.country_id',
                      datasourceType: 'remote',
                      mode: 'default',
                      allowClear: true,
                      modalType: 'baseinfo-country',
                    },
                    {
                      type: 'input',
                      name: 'state',
                      label: '州/省',
                      label_i18n: 'page.Induction.form.label.work_address_id.state',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写州/省',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.state',
                    },
                    {
                      type: 'input',
                      name: 'city',
                      label: '城市',
                      label_i18n: 'page.Induction.form.label.work_address_id.city',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写城市',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.city',
                    },
                    {
                      type: 'input',
                      name: 'street',
                      label: '街道',
                      label_i18n: 'page.Induction.form.label.work_address_id.street',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写街道',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.street',
                    },
                    {
                      type: 'input',
                      name: 'postcode',
                      label: '邮编',
                      label_i18n: 'page.Induction.form.label.work_address_id.postcode',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写邮编',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.postcode',
                    },
                  ],
                  rules: {},
                  model: reactive({
                    org_id: current_org?.id,
                    is_active: true,
                    adviser_id: adviser.value.adviser_id,
                    adviser_name: null,
                    street: null,
                    city: null,
                    state: null,
                    country_id: null,
                    postcode: null,
                    address_type: 0,
                  }),
                },
                options: {
                  address_type: [
                    {value: 0, name: t('page.Induction.form.label.home_address_id')},
                    {value: 1, name: t('page.Induction.form.label.work_address_id')},
                  ],
                },
                ok: (payload: any) => {
                  editModal1.loading = true;
                  return new Promise(resolve1 => {
                    create_address(payload)
                      .then((res: any) => {
                        message.success(t('page.Induction.form.title.Created_successfully'));
                        resolve1(null);
                        resolve(res.id as number);
                      })
                      .finally(() => {
                        editModal1.loading = false;
                      });
                  });
                },
              });
              modalBox(editModal1);
            });
          },
        },
      ],
      rules: {
        birth_date: [{ required: true, message: t('page.Induction.form.placeholder.birth_date') }],
        mobile: [{ required: true, message: t('page.Induction.form.placeholder.mobile') }],
        nationality: [{ required: true, message: t('page.Induction.form.placeholder.nationality') }],
        home_address_id: [{ required: true, message: t('page.Induction.form.placeholder.home_address_id') }],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: adviser.value.adviser_id,
      }),
      watch: {
        mobile: (data: any) => {
          if (data?.mobile && data.mobile.includes('+')) {
            data.mobile = data.mobile.replace(/\+/g, '00');
          }
        },
      },
    })
    // 增加表单修改标记
    // form_info.fields.map((item: any) => {
    //   (form_info.watch as any)[item.name] = () => {
    //     form_info.fields.map((item1: any) => {
    //       if (item1.name == item.name && !disabled_adviser.value) {
    //         item.suffix_icon = true;
    //       }
    //     })
    //   }
    // })
    const options_info = reactive({
      title: [
        {value: '', name: '-None-'},
        {value: 'Mr.', name: 'Mr.'},
        {value: 'Mrs.', name: 'Mrs.'},
        {value: 'Ms.', name: 'Ms.'},
        {value: 'Dr.', name: 'Dr.'},
        {value: 'Prof.', name: 'Prof.'},
      ],
      status: [
        {value: 1, name: t('page.Induction.options.status_1')},
        {value: 2, name: t('page.Induction.options.status_2')},
        {value: 3, name: t('page.Induction.options.status_3')},
        {value: 4, name: t('page.Induction.options.status_4')},
        {value: 5, name: t('page.Induction.options.status_5')},
        {value: 6, name: t('page.Induction.options.status_6')},
        {value: 7, name: t('page.Induction.options.status_7')},
        {value: 8, name: t('page.Induction.options.status_8')},
      ],
    });
    const {resetFields, validate, validateInfos} = useForm(form_info.model, form_info.rules);
    const local = ref(locales.global.locale);
    const form_bank = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [6, 7],
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'bankmanage_id',
          label: '选择银行',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bankmanage_id',
          disabled: disabled_bank,
          placeholder: '请选择银行名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id',
          datasourceType: 'remote',
          show_search: true,
          mode: 'default',
          allowClear: true,
          labelKey: local.value == 'en-US' ? 'english_name' : 'name',
          valueKey: 'id',
          modalType: 'baseinfo-bankmanage',
        },
        {
          type: 'input',
          name: 'bank_name',
          label: '银行名称',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bank_name',
          disabled: disabled || disabled_bank,
          placeholder: '请选择银行名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bank_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'english_name',
          label: '银行英文名称',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.english_name',
          disabled: disabled || disabled_bank,
          placeholder: '请选择银行英文名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.english_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'account_holder',
          label: '账户持有人',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.account_holder',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写账户持有人',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.account_holder',
        },
        {
          type: 'input',
          name: 'account_number',
          label: '账号',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.account_number',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写账号',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.account_number',
        },
        {
          type: 'textarea',
          name: 'note',
          label: '备注',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.note',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          maxlength: 200,
          minRows: 4,
          defaultValue: '',
          placeholder: '请填写备注',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.note',
        },
        {
          type: 'input',
          name: 'bank_address',
          label: '开户行地址',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bank_address',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写开户行地址',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bank_address',
        },
        {
          type: 'input',
          name: 'bic_or_swift_code',
          label: 'BIC/SWIFT CODE',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bic_or_swift_code',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写BIC/SWIFT CODE',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bic_or_swift_code',
        },
        {
          type: 'input',
          name: 'iban',
          label: 'IBAN',
          disabled: disabled_bank,
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.iban',
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写iban',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.iban',
        },
        {
          type: 'input',
          name: 'sort_code_or_branch_code',
          label: 'SORT/BRANCH CODE',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.sort_code_or_branch_code',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写SORT/BRANCH CODE',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.sort_code_or_branch_code',
        },
        {
          type: 'input',
          name: 'cnaps',
          label: 'CNAPS',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.cnaps',
          disabled: disabled_bank,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写cnaps',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.cnaps',
        },
        {
          type: 'radio',
          name: 'is_current',
          label: '是否当前账号',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.is_current',
          disabled: disabled_bank,
          allowClear: true,
          labelKey: 'name',
          valueKey: 'value',
          defaultValue: '',
        },
        {
          type: 'textarea',
          name: 'holder_address',
          label: '开户时向银行提供的住址',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.holder_address',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 512,
          minRows: 4,
          defaultValue: '',
          placeholder: '请填写开户时向银行提供的住址',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.holder_address',
        },
      ],
      rules: {
        bank_name: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_name')}],
        account_holder: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_holder')}],
        account_number: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_number')}],
        bank_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_address')}],
        is_current: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.is_current')}],
        bankmanage_id: [{required: true, message: t('page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id')}],
        holder_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.placeholder.holder_address')}],
        bic_or_swift_code: [{required: true, message: '中国的银行非招商银行需提供11位swift code，具体可以咨询开户银行工作人员。'}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        bankmanage_id: null,
        is_current: true,
        cnaps: null,
        sort_code_or_branch_code: null,
        iban: null,
        bank_address: null,
        bic_or_swift_code: null,
        english_name: null,
        bank_name: null,
        holder_address: null,
        note: null,
        account_holder: null,
        account_number: null,
      }),
      watch: {
        bankmanage_id: (data1: any) => {
          if (data1.bankmanage_id) {
            query_bank_manage_list({
              org_id: current_org?.id,
              ids: [data1.bankmanage_id].join(','),
            }).then((res: any) => {
              if (res.data[0].name == '其它') {
                disabled.value = false;
                data1['bank_name'] = '';
                data1['english_name'] = '';
                data1['bic_or_swift_code'] = '';
              } else {
                disabled.value = true;
                data1['bank_name'] = local.value == 'en-US' ? res.data[0].english_name : res.data[0].name;
                data1['english_name'] = res.data[0].english_name;
                data1['bic_or_swift_code'] = res.data[0].swift_code;
              }
            });
          } else {
            disabled.value = false;
            data1['bank_name'] = '';
            data1['english_name'] = '';
            data1['bic_or_swift_code'] = '';
          }

        },
      },
    })
    const options_bank = reactive({
      is_current: [
        {value: true, name: t('page.Induction.options_yin_hang_ZH.is_current.true')},
        {value: false, name: t('page.Induction.options_yin_hang_ZH.is_current.false')},
      ],
    });
    const {resetFields: resetFields1, validate: validate1, validateInfos: validateInfos1} = useForm(form_bank.model, form_bank.rules);
    const form_can_kao_hao = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [2, 2],
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'reference_number_type_id',
          label: '材料类别',
          label_i18n: 'page.Induction.form_can_kao_hao.label.reference_number_type_id',
          disabled: disabled_can_kao_hao,
          init_model: { is_adviser_select: true },
          placeholder: '请选择材料类别',
          placeholder_i18n: 'page.Induction.form_can_kao_hao.placeholder.reference_number_type_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-referencenumbertype',
        },
        {
          type: 'input',
          name: 'description',
          label: '编号',
          label_i18n: 'page.Induction.form_can_kao_hao.label.description',
          disabled: disabled_can_kao_hao,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写描述',
          placeholder_i18n: 'page.Induction.form_can_kao_hao.placeholder.description',
        },
        {
          type: 'input',
          name: 'name',
          label: '名称',
          label_i18n: 'page.Induction.form_can_kao_hao.label.name',
          disabled: disabled_can_kao_hao,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写名称',
          placeholder_i18n: 'page.Induction.form_can_kao_hao.placeholder.name',
        },
        {
          type: 'rangepicker',
          name: 'start_end_date',
          label: '开始-截止时间',
          label_i18n: 'page.Induction.form_can_kao_hao.label.start_end_date',
          disabled: disabled_can_kao_hao,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写起保日期时间',
          placeholder_i18n: 'page.Induction.form_can_kao_hao.placeholder.start_end_date',
        },
      ],
      rules: {
        name: [{required: true, message: t('page.Induction.form_can_kao_hao.rules.name')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        name: null,
        description: null,
        start_end_date: [],
        reference_number_type_id: null,
        appendix_json_list: [],
      }),
    })
    const options_can_kao_hao = reactive({});
    const {resetFields: resetFields2, validate: validate2, validateInfos: validateInfos2} = useForm(form_can_kao_hao.model, form_can_kao_hao.rules);
    const form_lian_xi = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '姓名',
          label_i18n: 'page.cerp.nextofkin.form.label.name',
          disabled: disabled_lian_xi,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写姓名',
          placeholder_i18n: 'page.cerp.nextofkin.form.placeholder.name',
        },
        {
          type: 'input',
          name: 'releationship',
          label: '关系',
          label_i18n: 'page.cerp.nextofkin.form.label.releationship',
          disabled: disabled_lian_xi,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写关系',
          placeholder_i18n: 'page.cerp.nextofkin.form.placeholder.releationship',
        },
        {
          type: 'input',
          name: 'contact_number',
          label: '联系电话',
          label_i18n: 'page.cerp.nextofkin.form.label.contact_number',
          disabled: disabled_lian_xi,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写联系电话',
          placeholder_i18n: 'page.cerp.nextofkin.form.placeholder.contact_number',
        },
        {
          type: 'input',
          name: 'email',
          label: '联系邮箱',
          label_i18n: 'page.cerp.nextofkin.form.label.email',
          disabled: disabled_lian_xi,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写联系邮箱',
          placeholder_i18n: 'page.cerp.nextofkin.form.placeholder.email',
        },
      ],
      rules: {
        name: [{required: true, message: t('page.Induction.form_can_kao_hao.rules.name')}],
        releationship: [{required: true, message:  t('page.Induction.form_can_kao_hao.rules.relationship')}],
        contact_number: [{required: true, message:  t('page.Induction.form_can_kao_hao.rules.Telephone')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        name: null,
        releationship: null,
        contact_number: null,
        email: null,
      }),
    })
    const options_lian_xi = reactive({});
    const {resetFields: resetFields3, validate: validate3, validateInfos: validateInfos3} = useForm(form_lian_xi.model, form_lian_xi.rules);
    const form_beneficiary = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [4, 4],
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '姓名',
          label_i18n: 'page.cerp.beneficiary.form.label.name',
          disabled: disabled_insurance,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写姓名',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.name',
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性别',
          label_i18n: 'page.cerp.beneficiary.form.label.gender',
          disabled: disabled_insurance,
          labelKey: 'name',
          valueKey: 'value',
          defaultValue: '',
        },
        {
          type: 'datepicker',
          name: 'date_of_birth',
          label: '出生日期',
          label_i18n: 'page.cerp.beneficiary.form.label.date_of_birth',
          disabled: disabled_insurance,
          allowClear: true,
          inputType: 'number',
          disabledTime: (date: any) => {
            return date >= moment();
          },
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写出生日期',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.date_of_birth',
        },
        {
          type: 'input',
          name: 'ratio',
          label: '受益比例(%)',
          label_i18n: 'page.cerp.beneficiary.form.label.ratio',
          disabled: disabled_insurance,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写受益比例(%)',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.ratio',
        },
        {
          type: 'remoteselect',
          name: 'id_type_id',
          label: '证件类别',
          label_i18n: 'page.cerp.beneficiary.form.label.id_type_id',
          disabled: disabled_insurance,
          placeholder: '请选择证件类别',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_type_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-idtype',
        },
        {
          type: 'input',
          name: 'id_number',
          label: '证件号',
          label_i18n: 'page.cerp.beneficiary.form.label.id_number',
          disabled: disabled_insurance,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写证件号',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_number',
        },
        {
          type: 'datepicker',
          name: 'id_expiry_date',
          label: '证件有效期',
          label_i18n: 'page.cerp.beneficiary.form.label.id_expiry_date',
          disabled: disabled_insurance,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写证件有效期',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_expiry_date',
        },
        {
          type: 'select',
          name: 'relation',
          label: '与被保险人关系',
          label_i18n: 'page.cerp.beneficiary.form.label.relation',
          mode: 'default',
          disabled: disabled_insurance,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择与被保险人关系',
          datasource: 'relation',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.relation',
        },
      ],
      rules: {
        name: [{required: true, message: t('page.Induction.rules.beneficiary.required')}],
        gender: [{required: true, message: t('page.Induction.rules.beneficiary.gender')}],
        date_of_birth: [{required: true, message: t('page.Induction.rules.beneficiary.date_of_birth')}],
        id_number: [{required: true, message: t('page.Induction.rules.beneficiary.id_number')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        name: null,
        relation: null,
        gender: null,
        date_of_birth: null,
        id_type_id: null,
        id_number: null,
        id_expiry_date: null,
        ratio: null,
      }),
    })
    const options_beneficiary = reactive({
      gender: [
        {value: 0, name: t('page.Induction.options_Insurance_beneficiary.gender0')},
        {value: 1, name: t('page.Induction.options_Insurance_beneficiary.gender1')},
      ],
      relation: [
        {value: 0, name: t('page.Induction.options_Insurance_beneficiary.relation0')},
        {value: 1, name: t('page.Induction.options_Insurance_beneficiary.relation1')},
        {value: 2, name: t('page.Induction.options_Insurance_beneficiary.relation2')},
        {value: 3, name: t('page.Induction.options_Insurance_beneficiary.relation3')},
        {value: 4, name: t('page.Induction.options_Insurance_beneficiary.relation4')},
      ],
    });
    const {resetFields: resetFields4, validate: validate4, validateInfos: validateInfos4} = useForm(form_beneficiary.model, form_beneficiary.rules);
    const form_social_security = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [3, 3],
      },
      fields: [
        {
          type: 'select',
          name: 'household_type',
          label: '户籍类型',
          label_i18n: 'page.cerp.social_insurance.form.label.household_type',
          mode: 'default',
          disabled: false,
          allowClear: true,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择户籍类型',
          placeholder_i18n: 'page.cerp.social_insurance.form.placeholder.household_type',
          datasource: 'household_type',
        },
        {
          type: 'input',
          name: 'city',
          label: '缴纳城市',
          label_i18n: 'page.cerp.social_insurance.form.label.city',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写缴纳城市',
          placeholder_i18n: 'page.cerp.social_insurance.form.placeholder.city',
        },
        {
          type: 'remoteselect',
          name: 'payment_item_ids',
          label: '缴纳项目',
          label_i18n: 'page.cerp.social_insurance.form.label.payment_item_ids',
          disabled: false,
          placeholder: '请选择缴纳项目',
          placeholder_i18n: 'page.cerp.social_insurance.form.placeholder.payment_item_ids',
          datasourceType: 'remote',
          mode: 'multiple',
          allowClear: true,
          modalType: 'cerp-query_payment_item_list',
        },
        {
          type: 'datepicker',
          name: 'join_date',
          label: '入职日期',
          label_i18n: 'page.cerp.social_insurance.form.label.join_date',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写入职日期',
          placeholder_i18n: 'page.cerp.social_insurance.form.placeholder.join_date',
        },
        {
          type: 'monthpicker',
          name: 'start_date',
          label: '起保月份',
          label_i18n: 'page.cerp.social_insurance.form.label.start_date',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          format: 'YYYY-MM',
          placeholder: '请填写起保月份',
          placeholder_i18n: 'page.cerp.social_insurance.form.placeholder.start_date',
        },
      ],
      rules: {
        payment_item_ids: [{required: true, message: t('page.cerp.social_insurance.form.placeholder.payment_item_ids')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        payment_item_ids: [],
        household_type: null,
        city: null,
        start_date: null,
        join_date: null,
        name: adviser.value.full_name,
      }),
    })
    const options_social_security = reactive({
      household_type: [
        { value: 0, name: t('page.Induction.options_social_security.household_type0') },
        { value: 1, name: t('page.Induction.options_social_security.household_type1') },
        { value: 2, name: t('page.Induction.options_social_security.household_type2') },
        { value: 3, name: t('page.Induction.options_social_security.household_type3') },
      ],
    });
    const {resetFields: resetFields5, validate: validate5, validateInfos: validateInfos5} = useForm(form_social_security.model, form_social_security.rules);
    query_adviser_list({
      org_id: current_org.id,
      ids: [adviser.value.adviser_id],
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        Object.assign(form_info.model, res.data[0]);
        Object.assign(data_list, res.data[0]);
        if(res.data[0].nationality__name != "China"){
          tags_list.value.map((item: any, index: any) => {
            if(item.key == '社保'){
              tags_list.value.splice(index,1);
            }
          })
        }
      }
    })
    const get_update_adviser_status = () => {
      get_wechat_update_record_status({
        org_id: 1, adviser_id: adviser.value.adviser_id, model_name: '顾问', object_id: adviser.value.adviser_id,
      })
        .then((resData: any) => {
          if (resData?.res === false && resData.message) {
            disabled_adviser.value = true;
          }
        });
    }
    get_update_adviser_status();
    const get_update_bank_status = (bank_id: any) => {
      get_wechat_update_record_status({
        org_id: 1, adviser_id: adviser.value.adviser_id, model_name: '银行账户信息', object_id: bank_id,
      })
        .then((resData: any) => {
          if (resData?.res === false && resData.message) {
            disabled_bank.value = true;
          } else {
            disabled_bank.value = false;
          }
        });
    }
    get_update_bank_status(null);
    const get_update_insurance_status = (insurance_id: any) => {
      get_wechat_update_record_status({
        org_id: 1, adviser_id: adviser.value.adviser_id, model_name: '受益人', object_id: insurance_id,
      })
        .then((resData: any) => {
          if (resData?.res === false && resData.message) {
            disabled_insurance.value = true;
          } else {
            disabled_insurance.value = false;
          }
        });
    }
    get_update_insurance_status(null);
    const get_update_lian_xi_status = (lian_xi_id: any) => {
      get_wechat_update_record_status({
        org_id: 1, adviser_id: adviser.value.adviser_id, model_name: '紧急联系人', object_id: lian_xi_id,
      })
        .then((resData: any) => {
          if (resData?.res === false && resData.message) {
            disabled_lian_xi.value = true;
          } else {
            disabled_lian_xi.value = false;
          }
        });
    }
    get_update_lian_xi_status(null);
    const get_update_can_kao_hao_status = (can_kao_hao_id: any) => {
      get_wechat_update_record_status({
        org_id: 1, adviser_id: adviser.value.adviser_id, model_name: '个人参考号', object_id: can_kao_hao_id,
      })
        .then((resData: any) => {
          if (resData?.res === false && resData.message) {
            disabled_can_kao_hao.value = true;
          } else {
            disabled_can_kao_hao.value = false;
          }
        });
    }
    get_update_can_kao_hao_status(null);
    const search = reactive({
      current: 1,
      pageSize: 10,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        num: null,
      },
    });
    const scgyfangan = reactive({
      title: t('page.Induction.bank_account'),
      column_flag: 'bankaccount',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-bankaccount',
      height: 500,
      mode: null,
    });
    const personal = reactive({
      title: t('router.title.cerp-personreferencenumber'),
      column_flag: 'personreferencenumber',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-personreferencenumber',
      height: 500,
      mode: null,
    });
    const nextofkin = reactive({
      title: t('router.title.cerp-nextofkin'),
      column_flag: 'nextofkin',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-nextofkin',
      height: 500,
      mode: null,
    });
    const beneficiary = reactive({
      title: t('page.Induction.Insurance_beneficiary'),
      column_flag: 'beneficiary',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-beneficiary',
      height: 500,
      mode: null,
    });
    const social_insurance = reactive({
      title: t('page.Induction.social_security'),
      column_flag: 'social_insurance',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-social_insurance',
      height: 500,
      mode: null,
    });
    const info = () => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'StatementModal',
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
      };
      modifyModal.cancel = () => {
      };
      modalBox(modifyModal);
    };

    bus.on('update_bank_account', obj => {
      Object.assign(form_bank.model, {...obj, bank_account_id: obj.id});
      get_update_bank_status(obj.id);
      is_bank_create.value = false;
      (form_bank.rules as any) = {};
    });
    bus.on('del_bank_account', obj => {
      if(is_induction_process.value){
        notification.error({
          message: t('page.login.reminder'),
          description: t('page.Induction.del'),
        });
      }else{
        Modal.confirm({
          title: () => t('page.pagemodel4.Delete_this'),
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              delete_bank_account({
                org_id: current_org.id,
                bank_account_id: obj.id,
              })
                .then((res: any) => {
                  message.success(t('page.pagemodel4.Deleted_successfully'));
                  Object.assign(obj, res);
                })
                .finally(() => {
                  resolve(null);
                });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      }
    });
    bus.on('create_bank_account', () => {
      get_update_bank_status(null);
      resetFields1();
      is_bank_create.value = true;
      (form_bank.rules as any) = {
        bank_name: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_name')}],
        account_holder: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_holder')}],
        account_number: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_number')}],
        bank_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_address')}],
        is_current: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.is_current')}],
      };
    });

    bus.on('update_personal', obj => {
      Object.assign(form_can_kao_hao.model, {
        ...obj,
        person_reference_number_id: obj.id,
        start_end_date: [obj?.start_date, obj?.end_date]
      });
      get_update_can_kao_hao_status(obj.id);
      file_list.value.length = 0;
      if (form_can_kao_hao.model.appendix_json_list && form_can_kao_hao.model.appendix_json_list.length > 0) {
        form_can_kao_hao.model.appendix_json_list.map((item: any) => {
          file_list.value.push({
            file_id: item.file_id,
            file_url: item.file_url,
            file_name: item.name,
          });
        })
      }
      is_can_kao_hao.value = false;
      (form_can_kao_hao.rules as any) = {};
    });
    bus.on('create_personal', () => {
      get_update_can_kao_hao_status(null);
      file_list.value.length = 0;
      is_can_kao_hao.value = true;
      (form_can_kao_hao.rules as any) = {
        name: [{required: true, message: t('page.Induction.form_can_kao_hao.rules.name')}],
      };
      resetFields2();
    });
    bus.on('update_nextofkin', obj => {
      Object.assign(form_lian_xi.model, {...obj, next_of_kin_id: obj.id});
      get_update_lian_xi_status(obj.id);
      is_lian_xi.value = false;
      (form_lian_xi.rules as any) = {};
    });
    bus.on('create_nextofkin', () => {
      resetFields3();
      get_update_lian_xi_status(null);
      is_lian_xi.value = true;
      (form_lian_xi.rules as any) = {
        name: [{required: true, message: t('page.Induction.rules.beneficiary.required')}],
        releationship: [{required: true, message: t('page.Induction.form_can_kao_hao.rules.relationship')}],
      };
    });
    bus.on('update_beneficiary', obj => {
      Object.assign(form_beneficiary.model, {...obj, beneficiary_id: obj.id});
      get_update_insurance_status(obj.id);
      is_beneficiary.value = false;
      (form_beneficiary.rules as any) = {};
    });
    bus.on('del_beneficiary', obj => {
      if(is_induction_process.value){
        notification.error({
          message: t('page.login.reminder'),
          description: t('page.Induction.del'),
        });
      }else{
        Modal.confirm({
          title: () => t('page.pagemodel4.Delete_this'),
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              delete_beneficiary({
                org_id: current_org.id,
                bank_account_id: obj.id,
              })
                .then((res: any) => {
                  message.success(t('page.pagemodel4.Deleted_successfully'));
                  Object.assign(obj, res);
                })
                .finally(() => {
                  resolve(null);
                });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      }
    });
    bus.on('create_beneficiary', () => {
      get_update_insurance_status(null);
      resetFields4();
      is_beneficiary.value = true;
      (form_beneficiary.rules as any) = {
        name: [{required: true, message: t('page.Induction.rules.beneficiary.required')}],
        gender: [{required: true, message: t('page.Induction.rules.beneficiary.gender')}],
        date_of_birth: [{required: true, message: t('page.Induction.rules.beneficiary.date_of_birth')}],
        id_number: [{required: true, message: t('page.Induction.rules.beneficiary.id_number')}],
      };
    });
    const search_resume = () => {
      query_resume_list({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          Object.assign(state, res.data[0]);
          state.language = res.data[0].language ? res.data[0].language : [];
          state.skill = res.data[0].skill ? res.data[0].skill : [];
          state.training = res.data[0].training ? res.data[0].training : [];
          state.datas = res.data[0].datas ? res.data[0].datas : [];
          state.educations = res.data[0].educations ? res.data[0].educations : [];
          state.experiences = res.data[0].experiences ? res.data[0].experiences : [];
          state.training = res.data[0].training ? res.data[0].training : [];
          state.resume_id = res.data[0].id;
          state.datas = res.data[0].datas;
          if (res.data[0].appendix_ids) {
            state.appendix_ids = res.data[0].appendix_ids.split(',');
          } else {
            state.appendix_ids = [];
          }
          percent.value = 25;
          if(state.educations?.length > 0){
            percent.value = percent.value + 25;
          }
          if(state.experiences?.length > 0){
            percent.value = percent.value + 25;
          }
          if(state.introduction){
            percent.value = percent.value + 25;
          }
          queryFileList();
          is_create1 = false;
        }else{
          percent.value = 25;
        }
      })
    }
    const queryFileList = () => {
      if (state.appendix_ids && state.appendix_ids.length > 0) {
        query_all_nsfile_list({
          fileids: state.appendix_ids,
        }).then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            file_list1.value.length = 0;
            res.data.map((item: any) => {
              file_list1.value.push({
                file_id: item.id,
                file_url: item.fileurl,
                file_name: item.name,
              });
            })
          }
        })
      }
    }

    const handlerOk = () => {
      if (is_create1) {
        create_resume({
          org_id: current_org.id,
          introduction: state.introduction,
          skill: JSON.stringify(state.skill),
          language: JSON.stringify(state.language),
          training: JSON.stringify(state.training),
          appendix_ids: state.appendix_ids,
          datas: JSON.stringify(state.datas),
          educations: JSON.stringify(state.educations),
          experiences: JSON.stringify(state.experiences),
          adviser_id: adviser.value.adviser_id,
        }).then((res: any) => {
          search_resume();
        })
      } else {
        update_resume({
          org_id: current_org.id,
          introduction: state.introduction,
          skill: JSON.stringify(state.skill),
          language: JSON.stringify(state.language),
          training: JSON.stringify(state.training),
          appendix_ids: state.appendix_ids,
          datas: JSON.stringify(state.datas),
          educations: JSON.stringify(state.educations),
          experiences: JSON.stringify(state.experiences),
          adviser_id: adviser.value.adviser_id,
          resume_id: state.resume_id,
        }).then((res: any) => {
          search_resume();
        })
      }
    }
    const handleUploadFile = () => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.file'),
        group: '简历附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            state.appendix_ids.push(...payload);
            queryFileList();
            resolve(null);
            handlerOk();
          });
        },
      });
      modalBox(editModal);
    }
    // 个人顾问信息保存
    const handleUpdateAdviser = () => {
        validate().then(() => {
            Modal.confirm({
              title: t('page.Induction.UpdateAdviser.title'),
              content: t('page.Induction.UpdateAdviser.content1'),
              onOk() {
                update_adviser_by_wechat({
                  ...form_info.model
                }).then((res: any) => {
                  message.success(res.message);
                  get_update_adviser_status();
                })
              },
              onCancel() {},
            });
        })
    }
    const handleUpdateBank = () => {
      if (is_bank_create.value) {
        validate1().then(() => {
          create_bank_account_by_wechat({
            ...form_bank.model,
          }).then(() => {
            message.success(t('page.Induction.UpdateBank.message'));
            (scgyfangan.search.requestParams.num as any) = num++;
            checked.value = false;
            disabled_bank.value = true;
            resetFields1();
          })
        })
      } else {
        update_bank_account_by_wechat({
          ...form_bank.model,
        }).then((res: any) => {
          if (res?.message) {
            message.success(res.message);
          } else {
            message.success(t('page.Induction.UpdateBank.message'));
          }
          (scgyfangan.search.requestParams.num as any) = num++;
          checked.value = false;
          disabled_bank.value = true;
          resetFields1();
        })
      }
    }
    const handleUpdatePersonal = () => {
      if (is_can_kao_hao.value) {
        validate2().then(() => {
          const file_ids: any = [];
          if (file_list.value.length > 0) {
            file_list.value.map((item: any) => {
              file_ids.push(item.file_id)
            })
          }
          create_person_reference_number_by_wechat({
            ...form_can_kao_hao.model,
            start_date: form_can_kao_hao.model.start_end_date?.[0] ? (form_can_kao_hao.model.start_end_date?.[0] + ' 00:00:00') : null,
            end_date: form_can_kao_hao.model.start_end_date?.[1] ? (form_can_kao_hao.model.start_end_date?.[1] + ' 00:00:00') : null,
            appendix_ids: file_ids,
          }).then(() => {
            message.success(t('page.Induction.UpdateBank.message'));
            (personal.search.requestParams.num as any) = num++;
            disabled_can_kao_hao.value = true;
            resetFields2();
          })
        })
      } else {
        const file_ids: any = [];
        if (file_list.value.length > 0) {
          file_list.value.map((item: any) => {
            file_ids.push(item.file_id)
          })
        }
        update_person_reference_number_by_wechat({
          ...form_can_kao_hao.model,
        }).then((res: any) => {
          if (res?.message) {
            message.success(res.message);
          } else {
            message.success(t('page.Induction.UpdateBank.message'));
          }
          (personal.search.requestParams.num as any) = num++;
          disabled_can_kao_hao.value = true;
          resetFields2();
        })
      }
    }
    const handleUpdateNextofkin = () => {
      if (is_lian_xi.value) {
        validate3().then(() => {
          create_next_of_kin_by_wechat({
            ...form_lian_xi.model,
          }).then(() => {
            message.success(t('page.Induction.UpdateBank.message'));
            (nextofkin.search.requestParams.num as any) = num++;
            resetFields3();
            disabled_lian_xi.value = true;
          })
        })
      } else {
        update_next_of_kin_by_wechat({
          ...form_lian_xi.model,
        }).then((res: any) => {
          if (res?.message) {
            message.success(res.message);
          } else {
            message.success(t('page.Induction.UpdateBank.message'));
          }
          (nextofkin.search.requestParams.num as any) = num++;
          resetFields3();
          disabled_lian_xi.value = true;
        })
      }
    }
    const handleUpdateBeneficiary = () => {
      if(is_beneficiary.value){
        validate4().then(() => {
          create_beneficiary_by_wechat({
            ...form_beneficiary.model,
          }).then(() => {
            message.success(t('page.Induction.UpdateBank.message'));
            (nextofkin.search.requestParams.num as any) = num++;
            resetFields4();
            disabled_insurance.value = true;
          })
        })
      }else{
        update_beneficiary_by_wechat({
          ...form_beneficiary.model,
        }).then((res: any) => {
          if(res?.message){
            message.success(res.message);
          }else{
            message.success(t('page.Induction.UpdateBank.message'));
          }
          (nextofkin.search.requestParams.num as any) = num++;
          resetFields4();
          disabled_insurance.value = true;
        })
      }
    }
    const handleSocialInsurance = () => {
      validate5().then(() => {
        loading_SocialInsurance.value = true;
        create_social_insurance({
          ...form_social_security.model,
        }).then(() => {
          message.success(t('page.Induction.UpdateBank.message'));
          (social_insurance.search.requestParams.num as any) = num++;
        }).finally(() => {
          loading_SocialInsurance.value = false;
        })
      })
    }
    const handleUpload = () => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.file'),
        group: '个人材料',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            query_all_nsfile_list({
              fileids: payload,
            }).then((res: any) => {
              if (res && res.data && res.data.length > 0) {
                res.data.map((item: any) => {
                  file_list.value.push({
                    file_id: item.id,
                    file_url: item.fileurl,
                    file_name: item.name,
                  });
                })
              }
            })
            resolve(null);
          });
        },
      });
      modalBox(editModal);
    }
    const openUrl = (record: any) => {
      if (record.file_url) {
        const url =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    }
    const delete_file = (record: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            file_list.value.map((item: any, index: any) => {
              if (record.file_id == item.file_id) {
                file_list.value.splice(index, 1);
              }
            })
            resolve(null);
          })
        },
        onCancel() {
        },
      })
    }

    const delete_file_list = (record: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.appendix_ids = [];
            file_list1.value.map((item: any, index: any) => {
              if (record.file_id == item.file_id) {
                file_list1.value.splice(index, 1);
              }
            })
            file_list1.value.map((item: any) => {
              state.appendix_ids.push(item.file_id);
            })
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const onTabChange = (value: string) => {
      activeKey.value = value;
    }
    const handleAddEducations = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.AddEducations.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '起止时间',
              label_i18n: 'page.cerp.AddEducations.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写起止时间',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.start_end_date',
            },
            {
              type: 'input',
              name: 'school',
              label: '学校',
              label_i18n: 'page.cerp.AddEducations.form.label.school',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学校',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.school',
            },
            {
              type: 'input',
              name: 'major',
              label: '专业',
              label_i18n: 'page.cerp.AddEducations.form.label.major',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写专业',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.major',
            },
            {
              type: 'input',
              name: 'education',
              label: '学历',
              label_i18n: 'page.cerp.AddEducations.form.label.education',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学历',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.education',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddEducations.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            education: null,
            major: null,
            school: null,
            start_end_date: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.educations.push({...payload, time: time});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdateEducations = (obj: any, index: any) => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.AddEducations.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '起止时间',
              label_i18n: 'page.cerp.AddEducations.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写起止时间',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.start_end_date',
            },
            {
              type: 'input',
              name: 'school',
              label: '学校',
              label_i18n: 'page.cerp.AddEducations.form.label.school',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学校',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.school',
            },
            {
              type: 'input',
              name: 'major',
              label: '专业',
              label_i18n: 'page.cerp.AddEducations.form.label.major',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写专业',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.major',
            },
            {
              type: 'input',
              name: 'education',
              label: '学历',
              label_i18n: 'page.cerp.AddEducations.form.label.education',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学历',
              placeholder_i18n: 'page.cerp.AddEducations.form.placeholder.education',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddEducations.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            education: obj.education,
            major: obj.major,
            school: obj.school,
            start_end_date: obj.start_end_date,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.educations.splice(index, 1, {...payload, time: time});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleDelEducations = (index: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.educations.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleAddExperiences = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.work_experience'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '时间',
              label_i18n: 'page.cerp.AddExperiences.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择时间',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.start_end_date',
            },
            {
              type: 'input',
              name: 'company',
              label: '公司',
              label_i18n: 'page.cerp.AddExperiences.form.label.company',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写公司',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.company',

            },
            {
              type: 'input',
              name: 'post',
              label: '职务',
              label_i18n: 'page.cerp.AddExperiences.form.label.post',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写职务',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.post',

            },
            {
              type: 'textarea',
              name: 'note',
              label: '工作描述',
              label_i18n: 'page.cerp.AddExperiences.form.label.note',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写工作描述',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.note',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddExperiences.form.tip',
            },
          ],
          rules: {},
          model: reactive({
            note: null,
            post: null,
            company: null,
            start_end_date: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.experiences.push({...payload, time: time})
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdateExperiences = (obj: any, index: any) => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.work_experience'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '时间',
              label_i18n: 'page.cerp.AddExperiences.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择时间',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.start_end_date',
            },
            {
              type: 'input',
              name: 'company',
              label: '公司',
              label_i18n: 'page.cerp.AddExperiences.form.label.company',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写公司',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.company',

            },
            {
              type: 'input',
              name: 'post',
              label: '职务',
              label_i18n: 'page.cerp.AddExperiences.form.label.post',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写职务',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.post',

            },
            {
              type: 'textarea',
              name: 'note',
              label: '工作描述',
              label_i18n: 'page.cerp.AddExperiences.form.label.note',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写工作描述',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.note',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddExperiences.form.tip',
            },
          ],
          rules: {},
          model: reactive({
            note: obj.note,
            post: obj.post,
            company: obj.company,
            start_end_date: obj.start_end_date,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.experiences.splice(index, 1, {...payload, time: time});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleAddTraining = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.Training_experience'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '时间',
              label_i18n: 'page.cerp.AddTraining.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择时间',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.start_end_date',

            },
            {
              type: 'input',
              name: 'book',
              label: '培训课程',
              label_i18n: 'page.cerp.AddTraining.form.label.book',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.book',

            },
            {
              type: 'input',
              name: 'mechanism',
              label: '培训机构',
              label_i18n: 'page.cerp.AddTraining.form.label.mechanism',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.mechanism',

            },
            {
              type: 'input',
              name: 'address',
              label: '培训地点',
              label_i18n: 'page.cerp.AddTraining.form.label.address',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写（选填）',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.address',

            },
            {
              type: 'textarea',
              name: 'note',
              label: '培训描述',
              label_i18n: 'page.cerp.AddExperiences.form.label.note',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写工作描述',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.note',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddTraining.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            note: null,
            mechanism: null,
            address: null,
            book: null,
            start_end_date: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.training.push({...payload, time: time})
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdateTraining = (obj: any, index: any) => {
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.Training_experience'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '时间',
              label_i18n: 'page.cerp.AddTraining.form.label.start_end_date',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择时间',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.start_end_date',

            },
            {
              type: 'input',
              name: 'book',
              label: '培训课程',
              label_i18n: 'page.cerp.AddTraining.form.label.book',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.book',

            },
            {
              type: 'input',
              name: 'mechanism',
              label: '培训机构',
              label_i18n: 'page.cerp.AddTraining.form.label.mechanism',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.mechanism',

            },
            {
              type: 'input',
              name: 'address',
              label: '培训地点',
              label_i18n: 'page.cerp.AddTraining.form.label.address',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写（选填）',
              placeholder_i18n: 'page.cerp.AddTraining.form.placeholder.address',

            },
            {
              type: 'textarea',
              name: 'note',
              label: '培训描述',
              label_i18n: 'page.cerp.AddExperiences.form.label.note',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写工作描述',
              placeholder_i18n: 'page.cerp.AddExperiences.form.placeholder.note',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddTraining.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            note: obj.note,
            mechanism: obj.mechanism,
            address: obj.address,
            book: obj.book,
            start_end_date: obj.start_end_date,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            let time: any = '';
            if(payload.start_end_date && payload.start_end_date?.length > 0){
              time = payload.start_end_date[0] + '-' + payload.start_end_date[1];
            }
            state.training.splice(index, 1, {...payload, time: time});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleDelExperiences = (index: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.experiences.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleDelTraining = (index: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.training.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleDelSkill = (index: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.skill.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleDelLanguage = (index: any) => {
      Modal.confirm({
        title: t('page.Induction.delete_file.title'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.language.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleAddData = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddData.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'attributetable',
              name: 'datas',
              label: '额外信息',
              label_i18n: 'page.cerp.AddData.form.label.datas',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写额外信息',
              placeholder_i18n: 'page.cerp.AddData.form.placeholder.datas',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddData.form.label.tip',
            },
          ],
          rules: {},
          model: reactive({
            datas: state.datas,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.datas = payload.datas;
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleAddSkill = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddSkill.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'datepicker',
              name: 'time',
              label: '获得时间',
              label_i18n: 'page.cerp.AddSkill.form.label.datepicker',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.datepicker',
            },
            {
              type: 'input',
              name: 'certificate',
              label: '证书',
              label_i18n: 'page.cerp.AddSkill.form.label.certificate',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写证书',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.certificate',
            },
            {
              type: 'input',
              name: 'achievement',
              label: '成绩',
              label_i18n: 'page.cerp.AddSkill.form.label.achievement',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写（选填）',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.achievement',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddSkill.form.label.tip',
            },
          ],
          rules: {},
          model: reactive({
            time: null,
            certificate: null,
            achievement: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.skill.push({ ...payload })
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdateSkill = (obj: any, index: any) => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddSkill.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'datepicker',
              name: 'time',
              label: '获得时间',
              label_i18n: 'page.cerp.AddSkill.form.label.datepicker',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.datepicker',
            },
            {
              type: 'input',
              name: 'certificate',
              label: '证书',
              label_i18n: 'page.cerp.AddSkill.form.label.certificate',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写证书',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.certificate',
            },
            {
              type: 'input',
              name: 'achievement',
              label: '成绩',
              label_i18n: 'page.cerp.AddSkill.form.label.achievement',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写（选填）',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.achievement',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddSkill.form.label.tip',
            },
          ],
          rules: {},
          model: reactive({
            time: obj.time,
            certificate: obj.certificate,
            achievement: obj.achievement,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.skill.splice(index, 1, {...payload});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleAddLanguage = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddLanguage.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'language',
              label: '技能/语言',
              label_i18n: 'page.cerp.AddLanguage.form.label.language',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddLanguage.form.placeholder.language',
            },
            {
              type: 'select',
              name: 'certificate',
              label: '掌握程度',
              label_i18n: 'page.cerp.AddLanguage.form.label.certificate',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.datepicker',
              datasource: 'certificate',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddLanguage.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            language: null,
            certificate: null,
          }),
        },
        options: {
          certificate:[
            { value: 1, name: t('page.cerp.AddLanguage.options.certificate1') },
            { value: 2, name: t('page.cerp.AddLanguage.options.certificate2') },
            { value: 3, name: t('page.cerp.AddLanguage.options.certificate3') },
            { value: 4, name: t('page.cerp.AddLanguage.options.certificate4') },
          ]
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.language.push({ ...payload })
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdateLanguage = (obj: any, index: any) => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddLanguage.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'language',
              label: '技能/语言',
              label_i18n: 'page.cerp.AddLanguage.form.label.language',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写',
              placeholder_i18n: 'page.cerp.AddLanguage.form.placeholder.language',
            },
            {
              type: 'select',
              name: 'certificate',
              label: '掌握程度',
              label_i18n: 'page.cerp.AddLanguage.form.label.certificate',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择',
              placeholder_i18n: 'page.cerp.AddSkill.form.placeholder.datepicker',
              datasource: 'certificate',
            },
            {
              type: 'tip',
              name: '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
              name_i18n: 'page.cerp.AddLanguage.form.placeholder.tip',
            },
          ],
          rules: {},
          model: reactive({
            language: obj.language,
            certificate: obj.certificate,
          }),
        },
        options: {
          certificate:[
            { value: 1, name: t('page.cerp.AddLanguage.options.certificate1') },
            { value: 2, name: t('page.cerp.AddLanguage.options.certificate2') },
            { value: 3, name: t('page.cerp.AddLanguage.options.certificate3') },
            { value: 4, name: t('page.cerp.AddLanguage.options.certificate4') },
          ]
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.language.splice(index, 1, {...payload});
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }

    const handleAddIntroduction = () => {
      const editModal = reactive({
        visible: true,
        title: t('page.cerp.AddIntroduction.title'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'textarea',
              name: 'introduction',
              label: '自我评价',
              label_i18n: 'page.cerp.AddIntroduction.form.label.introduction',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写自我评价',
              placeholder_i18n: 'page.cerp.AddIntroduction.form.placeholder.introduction',
            },
          ],
          rules: {},
          model: reactive({
            introduction: state.introduction,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.introduction = payload.introduction;
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleExportResume = () => {
      if(state.resume_id){
        export_resume({
          org_id: current_org.id,
          resume_id: state.resume_id,
        }).then((res: any) => {
          if(res){
            window.open(res, '_blank');
          }
        })
      }else{
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.Resume_not_created_yet'),
        });
      }

    }
    search_resume();
    return {
      t,
      tags_list,
      loading_Nextofkin,
      loading_Beneficiary,
      loading_SocialInsurance,
      options_info,
      options_bank,
      options_can_kao_hao,
      options_lian_xi,
      options_beneficiary,
      options_social_security,
      handleUpdateAdviser,
      handleUpdateLanguage,
      handleUpdatePersonal,
      handleUpdateNextofkin,
      handleUpdateBeneficiary,
      handleAddTraining,
      handleUpload,
      onTabChange,
      info,
      form_info,
      form_bank,
      form_can_kao_hao,
      form_lian_xi,
      form_beneficiary,
      form_social_security,
      disabled_adviser,
      disabled_can_kao_hao,
      disabled_insurance,
      scgyfangan,
      personal,
      nextofkin,
      beneficiary,
      handleUpdateBank,
      handleSocialInsurance,
      handleExportResume,
      social_insurance,
      is_bank_create,
      is_can_kao_hao,
      is_lian_xi,
      is_beneficiary,
      validateInfos5,
      validateInfos4,
      validateInfos3,
      validateInfos2,
      validateInfos1,
      validateInfos,
      activeKey,
      checked,
      file_list,
      delete_file,
      openUrl,
      state,
      data_list,
      handleAddEducations,
      handleUpdateSkill,
      handleUpdateEducations,
      handleDelEducations,
      handleDelTraining,
      handleUpdateTraining,
      handleDelExperiences,
      handleAddExperiences,
      handleUpdateExperiences,
      handleDelSkill,
      handleDelLanguage,
      handleAddSkill,
      handleAddLanguage,
      handleAddIntroduction,
      handleAddData,
      file_list1,
      disabled_bank,
      disabled_lian_xi,
      is_induction_process,
      percent,
      handlerOk,
      handleUploadFile,
      delete_file_list,
    };
  },
});
